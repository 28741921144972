import { Box } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { heroVariantPropType } from '@/constants/propTypes';
import { trackBottomCTAButtonClick } from '@/helpers/dataLayer';
import { useDirectRequestDialogContext } from '@/hooks/useDirectRequestDialog';

import { Button } from './style';

/**
 * BottomCTA.
 */
const BottomCTA = ({ heroVariant }) => {
  const { openDialog } = useDirectRequestDialogContext();

  const handleClick = () => {
    trackBottomCTAButtonClick();
    if (heroVariant === 'category_page') {
      openDialog();
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Box display="flex" mt={3} mb={14.5} justifyContent="center">
      <Button
        size="large"
        data-cy="button: bottomCTA"
        onClick={handleClick}
        className="mrkt-btn_bottom-anfragen">
        <FormattedMessage id="main_seo_cta" />
      </Button>
    </Box>
  );
};

BottomCTA.propTypes = {
  heroVariant: heroVariantPropType,
};

BottomCTA.defaultProps = {
  heroVariant: null,
};

export default BottomCTA;
